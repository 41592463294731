body {
  background-color: #f4f4f4;
}

.container {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 100%; /* Stack columns vertically */

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.card-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.card-content-wrapper.disabled {
  flex-direction: column;
}

.card-content-wrapper.disabled .card-logo {
  width: 10%;
  margin-right: 0;
  margin-top: 10px;
}

.card-logo {
  width: 30%;
  max-height: 175px;
  object-fit: contain;
  margin-right: 10%;
  min-width: 80px;
}

.card-content-wrapper .card-copy {
  text-align: right;
}

.dashboard-card {
  // Add a drop shadow to each card
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
