.date-picker-readonly input {
  color: lightgray;
}

.select-readonly .MuiInputBase-readOnly {
  color: lightgray;
}

.goal-select {
  display: flex;
  justify-content: flex-start;
}

.goal-select p {
  margin-left: 10px;
}
