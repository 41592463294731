.card-content-wrapper .leaderboard-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 60%;
}

.card-content-wrapper .leaderboard-note {
  flex-basis: 100%;
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
}
